h2 {
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
}

.code {
  font-family: "Thermo TT ZeroTrial", sans-serif;
  font-size: 5.9rem;
  line-height: 5.6rem;
  margin: 0;
}

@keyframes marquee {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

.marqueeStatus.open {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 1.2rem;
  background: lime;
  color: black;
  padding: 0.3rem 0 0px;
  margin: 0.7rem 0 0 0;
}

.marqueeStatus.open span {
  float: left;
  width: calc(100% / 4);
}

.marqueeStatus.open div {
  display: block;
  width: 200%;
  height: 2rem;

  position: absolute;
  overflow: hidden;

  animation: marquee 7s linear infinite;
}

.marqueeStatus.closed {
  width: 100%;
  background-color: red;
  color: black;
  height: 1.2rem;
  padding: 0.3rem 0 0px;
  margin: 0.7rem 0 0 0;
}

.marqueeStatus.closed span:not(:last-child) {
  display: none;
}

.address {
  padding: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  text-align: left;
  text-decoration: none;
  border-bottom: #3c3c3c solid 1px;
}

.address__name {
  margin: 0.2rem 0 0 0.5rem;
  color: white;
  text-decoration: none;
}

.scheduleSection {
  display: flex;
  width: 100%;
  padding: 0.4rem 1rem 0.7rem 1rem;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  position: relative;
  border-bottom: #3c3c3c solid 1px;
}

.scheduleSection__title {
  margin: 0.8rem 0 0.35rem;
}

h3 {
  font-size: 1rem;
  font-weight: normal;
  margin: 0.8rem 0 0;
  color: #666666;
}

.toggleButton {
  display: inline-block;
  position: absolute;
  width: 1rem;
  height: 1rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  right: 1rem;
  top: 1.4rem;
}

.toggleButtonSpan {
  display: block;
  position: absolute;
  width: 9px;
  height: 1px;
  background-color: white;
  transform-origin: bottom left;
}

.toggleButtonSpan:first-child {
  transform: rotate(227deg);
  left: 12px;
  top: 12px;
}

.toggleButtonSpan:last-child {
  transform: rotate(-45deg);
  left: 12px;
  top: 13px;
}

.nextBreakTime {
  margin: 0;
}

.infos {
  display: flex;
  padding: 0.3rem 1rem 0.3rem 0;
  box-sizing: border-box;
}

.infos__title {
  margin: 1rem 1.6rem 1rem 1rem;
}

.infos__paragraph {
  text-align: left;
}

.period {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.periodWrapper {
  width: 100%;
}

.period__ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-family: "Helvetica Monospaced Pro";
}
