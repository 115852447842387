@font-face {
  font-family: "Thermo TT ZeroTrial";
  src:
    url("assets/fonts/ThermoTTTrial-Zero.woff2") format("woff2"),
    url("assets/fonts/ThermoTTTrial-Zero.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue";
  src:
    url("assets/fonts/HelveticaNeue-Medium.woff2") format("woff2"),
    url("assets/fonts/HelveticaNeue-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Monospaced Pro";
  src:
    url("assets/fonts/HelveticaMonospacedPro-Rg.woff2") format("woff2"),
    url("assets/fonts/HelveticaMonospacedPro-Rg.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.App {
  text-align: center;
}

:root {
  --primaryColor: black;
}

html {
  font-size: 12px;
}

body {
  font-family: "Helvetica Neue", sans-serif;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  line-height: 1.2rem;
  overflow: hidden;
  height: 100dvh;
  background: black;
  color: #ff6807;
}

h1 {
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

input,
select,
button,
textarea {
  font-size: 1rem;
  font-family: "Helvetica Neue", sans-serif;
  color: #ff6807;
}

.hub {
  position: absolute;
  margin: 1rem;
  width: calc(100vw - 2rem);
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.7rem;
  box-sizing: border-box;
  max-height: calc(100dvh - 4rem);
  overflow: scroll;
}
.header {
  display: flex;
  justify-content: center;
  width: 100%;
  background: var(--primaryColor);
  padding: 0.7rem;
  box-sizing: border-box;
  position: relative;
  z-index: 5;
  max-width: 500px;
}

.header__plus {
  cursor: pointer;
  position: absolute;
  left: 1rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease;
  top: 1rem;
}
.header__plus::after {
  content: "";
  width: 100%;
  height: 1px;
  background: #ff6807;
}
.header__plus::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  background: #ff6807;
}
.header__plus--cross {
  transform: rotate(45deg);
}
.header__about {
  font-family: "Thermo TT ZeroTrial";
  position: absolute;
  right: 1rem;
  top: 1rem;
  color: #ff6807;
  border: 1px solid #ff6807;
  border-radius: 50%;
  width: 1.9rem;
  height: 1.9rem;
  font-size: 1.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: none;
  font-size: 1.4rem;
  cursor: pointer;
}

.about {
  padding: 0 1rem 1rem;
  box-sizing: border-box;
  text-align: left;
}

.about h1 {
  text-align: center;
  margin: 0;
}

.about__chapo {
  font-size: 2.375rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.25rem;
  letter-spacing: -0.00875rem;
  text-transform: none;
  margin: 2rem 0 4rem;
}

.about__text {
  font-size: 1.2625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4875rem;
  text-transform: none;
  margin: 0 0 4rem 0;
}

.about__link {
  text-decoration: none;
  color: #ff6807;
  font-family: "Thermo TT ZeroTrial", sans-serif;
  margin: 0 1rem 0 0;
  font-size: 1.7rem;
}

.map {
  width: 100%;
  height: calc(100dvh + 2rem);
  object-fit: cover;
}

.hub__container {
  color: #ff6807;
  background: #000000;
  z-index: 5;
  width: 100%;
  max-width: 500px;
  border-radius: 0 0 1rem 1rem;
  overflow: hidden;
  transition: height 0.3s ease-out;
  height: 0;
}

.hub__container--open {
  /* height will be set dynamically via JavaScript */
}

.App-link {
  color: #61dafb;
}

.marker {
  position: relative;
}
.marker__circle {
  position: absolute;
  left: 0;
  animation: rotation infinite 4s linear;
}
.mapWrapper {
  width: 100%;
  height: 100%;
}

.mapWrapper__background {
  filter: (1);
  width: 100%;
  height: 100%;
}
.form {
  padding: 1rem;
  box-sizing: border-box;
}
.form__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 0 0.6rem;
  border-top: 1px solid #ff6807;
  position: relative;
}
.form__row--increment {
  padding: 0.2rem 0;
  position: relative;
}
.form__row--radio::before {
  content: "";
  position: absolute;
  top: 4px;
  left: var(--pseudo-left);
  height: calc(100% - 8px);
  width: var(--pseudo-width);
  background-color: #ff6807;
  border-radius: 1rem;
  transition:
    left 0.3s ease,
    width 0.3s ease;
  z-index: -1;
}
.form__input {
  border-color: inherit;
  background: none;
  text-transform: uppercase;
  border: none;
  font-family: "Helvetica Neue", sans-serif;
  padding: 0;
}
.form__input:focus,
.form__input:focus-visible {
  border: none;
  border-bottom: 1px solid #ff6807;
  outline: none;
}
.form__input::placeholder,
textarea::placeholder {
  color: #ff66076f;
}
.form__input--name {
  width: 10rem;
}
.form__input--nickname {
  width: 10rem;
  text-align: right;
}
.form__input--address {
  text-align: left;
  width: 100%;
  padding: 0.8rem 0 0.6rem;
  border-top: 1px solid #ff6807;
}
.form__increment {
  display: flex;
  width: 50%;
  justify-content: space-between;
}
.form__increment:last-child {
  margin: 0 0 0 1.7rem;
}
.form__increment--buttons {
  display: flex;
  align-items: center;
}

.form__increment--buttons button {
  text-decoration: none;
  border: none;
  background: none;
  color: #ff6807;
}
.form__increment--minus {
  padding: 0 0.3rem 0.22rem 0;
}
.form__increment--plus {
  padding: 0 0.3rem 0.1rem;
}
.form__increment--label {
  line-height: 2rem;
}
.form__increment--separator {
  width: 1px;
  height: 100%;
  background: #d0d0d0;
  left: 50%;
  top: 0;
  position: absolute;
}

input[type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  z-index: 1; /* Ensure it stays on top */
  padding: 0 1rem;
}
input[type="time"] {
  font-family: "Helvetica Neue", sans-serif;
  appearance: none;
  border: none;
  border-bottom: 1px solid #ff6807;
  background: none;
  padding: 0 0 0 0px;
  margin: 0 0.5rem 0 5px;
  color: #ff6807;
  font-size: 1rem;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}
input[type="radio"] + span {
  transition: color 0.3s ease;
}
input[type="radio"]:checked + span {
  color: black;
}
.form__radio__label {
  padding: 0 1rem;
  width: 50%;
}

.form__checkbox__label {
  display: flex;
  align-items: center;
}
.form__checkbox__label:before {
  content: "";
  width: 1rem;
  height: 1rem;
  margin: 0 0.5rem 0.2rem 0;
  border: 1px solid #ff6807;
  border-radius: 2px;
  background: black;
  transition: 0.3s ease;
  border-radius: 0;
}
.form__checkbox__input {
  height: 1.4rem;
  width: 1.3rem;
  position: absolute;
  left: 0;
  margin: 0;
  top: 8px;
  opacity: 0;
}
.form__checkbox__input:checked + .form__checkbox__label:before {
  background: #ff6807;
  border-radius: 2rem;
}
.form__checkbox__input:checked + .form__checkbox__label:after {
  content: "";
  background: black;
  position: absolute;
  border-radius: 2rem;
}
textarea {
  padding: 0.8rem 0 0.6rem;
  font-family: "Helvetica Neue", sans-serif;
  width: 100%;
  background: none;
  border: none;
  min-height: 5rem;
  text-transform: uppercase;
  border-top: 1px solid #ff6807;
}
select {
  text-decoration: none;
  background: transparent;
  color: #ff6807;
  border: none;
  text-transform: uppercase;
  font-family: "Helvetica Neue", sans-serif;
  border-bottom: 1px solid #ff6807;
  padding: 0 0 0.05rem 0px;
  margin: 0 0.5rem 0 5px;
  appearance: none;
  width: 6.8rem;
}

.period {
  text-align: left;
  line-height: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  padding: 0.4rem 0 0.7rem;
  border-top: 1px solid rgb(63 63 63);
  position: relative;
}
.period:first-child {
  border-color: #ff6807;
}
.period-header {
  width: 100%;
}

.minus,
.plus {
  border: 1px solid #ff6807;
  border-radius: 1rem;
  color: #ff6807;
  appearance: none;
  background: transparent;
  width: 1.3rem;
  height: 1.3rem;
  padding: 0;
}

.break {
  /* margin: 0 0.5rem 0 0; */
  width: 100%;
  position: relative;
}
.break:last-of-type {
  width: auto;
}

.add-period-button {
  width: 100%;
  text-align: left;
  color: #ff66076f;
  background: none;
  border: none;
  text-transform: uppercase;
  padding: 0.5rem 0;
  font-family: "Helvetica Neue", sans-serif;
  border-top: 1px solid #ff66076f;
  border-radius: 0;
}
.add-break-button {
  margin: 1px 0 0 0.3rem;
}
.remove-period-button {
  color: #ff66076f;
  background: none;
  border: none;
  position: absolute;
  top: 0.6rem;
  right: 0;
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: -0.02rem;
  padding: 0;
}
.map::before {
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 100vw;
  height: 100dvh;
  background: linear-gradient(
    180deg,
    rgba(255, 61, 0, 0.7) 0.25%,
    rgba(255, 122, 0, 0.7) 85.9%
  );
  background-blend-mode: hue;
  mix-blend-mode: plus-lighter;
  pointer-events: none;
}
.submit {
  text-decoration: none;
  color: #ff6807;
  font-family: "Thermo TT ZeroTrial", sans-serif;
  padding: 0.4rem 1rem;
  border-radius: 1rem;
  font-size: 1.7rem;
  border: 1px solid #ff6807;
  text-transform: uppercase;
  background: none;
  margin: 3rem 0 2rem;
}
.successful {
  font-family: "Thermo TT ZeroTrial", sans-serif;
  font-size: 1.7rem;
  margin: 3rem 1rem;
  line-height: 2rem;
}
.moderation-page {
  height: 100dvh;
  overflow: scroll;
}
.moderation-header {
  display: flex;
  align-items: center;
}
.moderation-button {
  appearance: none;
  background: black;
  border: 1px solid;
  border-radius: 2rem;
  height: fit-content;
  padding: 1rem;
  margin: 0 0 0 2rem;
  cursor: pointer;
}
.moderation-button:hover {
  background: #ff6807;
  color: black;
}
.moderation-page input,
.moderation-page input,
.moderation-page textarea,
.moderation-page select {
  color: #ff6807 !important;
  background: black;
}
.moderation-page select {
  width: auto;
}

.moderation-page h1 {
  font-size: 2.6rem;
}
.moderation-page h2 {
  margin: 2rem 0 0 0;
  font-size: 1.6rem;
}
.moderation-page h2:last-of-type {
  font-size: 1.2rem;
}
.playground-list {
  display: flex;
}
.playground-column {
  width: calc(100% / 3);
}
.playground-item {
  margin: 2rem;
  border-top: 1px solid #ff6807;
  padding: 2rem 0;
}
.pac-container {
  font-family: "Helvetica Neue", sans-serif !important;
}
.pac-logo:after {
  display: none;
}

.cluster-label {
  position: relative;
  left: -1px;  /* Adjust this value to move left/right */
  top: 0px;   /* Adjust this value to move up/down */
}

iframe + div {
  border: none !important;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
